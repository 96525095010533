import { Progress, Tooltip } from 'antd';
import { CalendarOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { Link } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { Workshop } from '../../types/workshop';
import { CLIENT_FRONTEND_URL } from '../../constants/api';
import { getCountryByCode } from '../../constants/countries';

const columns = (selectedDate: Moment | null) => [
  {
    title: 'Details',
    render: (record: Workshop) => (
      <div className="details">
        <div className="workshopTitle">
          <p className="workshopLink">
            <Link
              to={{
                pathname: `${CLIENT_FRONTEND_URL}/workshops/${record?.techniqueSlug}/${record?.slug}`,
              }}
              target="_blank"
            >
              {record.title} <small>L{record.level}</small>
            </Link>
          </p>
          {record?.isHidden ? (
            <Tooltip placement="top" title="Workshop Hidden">
              <EyeInvisibleOutlined />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Workshop Public">
              <EyeOutlined />
            </Tooltip>
          )}
        </div>
        <small>
          {record?.workshopMode === 'virtual' ? 'Online' : record?.city}
          {record.country && `, ${getCountryByCode(record.country)?.name}`}
        </small>
        <br />
        <small>
          <Tooltip placement="topLeft" title="Workshop Start Date">
            <CalendarOutlined />{' '}
            {moment(record.startDate)
              .tz(record.timezone || 'UTC')
              ?.format('MMM Do YYYY, h:mm A')}
            ,{record.timezone}
          </Tooltip>
        </small>
        <br />
        <small>
          <Tooltip placement="topLeft" title="Sale End Date">
            <ShoppingCartOutlined />{' '}
            {moment(record.ticketEndDate)
              .tz(record.timezone)
              ?.format('MMM Do YYYY, h:mm A')}
          </Tooltip>
        </small>
      </div>
    ),
    key: 'details',
    width: 300,
  },
  {
    title: 'Ticket Progress',
    render: (record: Workshop) => (
      <div>
        <Progress
          format={(percent) =>
            `${record?.closedTickets ? record?.closedTickets : 0}/${
              record?.totalTickets ? record?.totalTickets : 0
            }`
          }
          percent={(record?.closedTickets / record?.totalTickets) * 100}
          showInfo={true}
          type="line"
          strokeWidth={20}
          strokeColor="green"
          strokeLinecap="square"
        />
        <div style={{ marginTop: '0.5rem' }}>
          {record.soldIn24Hrs || 0} sold in 24h | {record.soldIn7Days || 0} sold
          in 7d
          {selectedDate && ( // Conditionally display soldOnDate only when a date is selected
            <>
              {' '}
              | {record.soldOnDate || 0} sold on {selectedDate.format('MMM Do')}
            </>
          )}
        </div>
      </div>
    ),
    key: 'ticket_progress',
    width: 400,
  },
];

export default columns;
