import { Moment } from 'moment';

import fetchClient from '../utils/fetch-client';
import {
  WORKSHOPS,
  WORKSHOPATTENDEES,
  INSTRUCTOR,
  // RECEIPT,
} from '../constants/api';
import {
  IExpenseFieldValue,
  IExternalIncomeFieldValue,
  IWorkshopCoupon,
} from '../types/workshop';
import {
  WorkshopDetail,
  UpdateAttendee,
  EmailAttendees,
  WorkshopPhotos,
  AttendeeAttendence,
  WaitListEmail,
  IAddAttendee,
} from '../types/workshop';

export const getWorkshops = ({
  createdBy,
  status,
  region,
  date,
}: {
  createdBy?: string;
  status?: string;
  region?: string;
  date?: Date;
}) => {
  return fetchClient.get(
    `${WORKSHOPS.WORKSHOPS}?createdBy=${createdBy ?? 'all'}&status=${
      status ?? ''
    }&region=${region ?? 'All'}&date=${date ?? ''}`
  );
};
export const getNewWorkshops = (isHiddenVisible?: boolean) => {
  return fetchClient.get(
    `${WORKSHOPS.NEW_WORKSHOPS}?isHiddenVisible=${isHiddenVisible}`
  );
};

export const getWorkshopsForTransfer = () => {
  return fetchClient.get(`${WORKSHOPS.WORKSHOPS}/workshop-for-transfer`);
};

export const getWorkshopsBySlug = (workshopSlug: string) => {
  return fetchClient.get(`${WORKSHOPS.WORKSHOPS}/${workshopSlug}`);
};

export const getFiles = (type: string) => {
  return fetchClient.get(`${WORKSHOPS.GET_FILES}?type=${type}`);
};

export const createWorkshop = (payload: WorkshopDetail) => {
  return fetchClient.post(WORKSHOPS.WORKSHOPS, payload);
};

export const updateWorkshop = (
  payload: Partial<WorkshopDetail>,
  workshopSlug: string
) => {
  return fetchClient.put(`${WORKSHOPS.WORKSHOPS}/${workshopSlug}`, payload);
};

export const toggleWorkshopVisibility = (
  payload: { isHidden: boolean },
  workshopSlug: string
) => {
  return fetchClient.put(`${WORKSHOPS.WORKSHOPS}/${workshopSlug}`, payload);
};

export const mailWorkshop = (attendeesId: string) => {
  return fetchClient.post(`${WORKSHOPS.SEND_MAIL_ATTENDEE}/${attendeesId}`);
};

export const getWorkshopAttendees = (workshopSlug: string) => {
  return fetchClient.get(
    `${WORKSHOPS.ATTENDEES}/all-attendees/${workshopSlug}`
  );
};

export const getWorkshopIncomeData = (workshopId: string) => {
  return fetchClient.get(
    `${WORKSHOPS.WORKSHOPS}/workshop-income/${workshopId}`
  );
};

export const addWorkshopAttendee = (
  workshopSlug: string,
  payload: IAddAttendee
) => {
  return fetchClient.post(`${WORKSHOPS.ATTENDEES}/${workshopSlug}`, payload);
};

export const updateWorkshopAttendee = (
  attendeesId: string,
  payload: UpdateAttendee
) => {
  return fetchClient.put(`${WORKSHOPS.ATTENDEES}/${attendeesId}`, payload);
};
export const deleteWorkshop = (
  workshopSlug?: string,
  payload?: { reason: string }
) => {
  return fetchClient.post(
    `${WORKSHOPS.WORKSHOPS}/delete/${workshopSlug}`,
    payload
  );
};

export const createEmailAttendees = (payload: EmailAttendees) => {
  return fetchClient.post(`${WORKSHOPATTENDEES.MAIL}`, payload);
};

export const updateEmailAttendees = ({
  mailId,
  payload,
}: {
  mailId: string;
  payload: Partial<EmailAttendees>;
}) => {
  return fetchClient.put(`${WORKSHOPATTENDEES.MAIL}/${mailId ?? ''}`, payload);
};

export const getEmailAttendees = (workshopId: string) => {
  return fetchClient.get(`${WORKSHOPATTENDEES.MAIL}/${workshopId ?? ''}`);
};

export const deleteEmailAttendees = (mailId: string) => {
  return fetchClient.delete(`${WORKSHOPATTENDEES.MAIL}/${mailId ?? ''}`);
};

export const updateWorkshopAttendence = (payload: AttendeeAttendence[]) => {
  return fetchClient.put(`${WORKSHOPS.ATTENDEES}/attendence/update`, payload);
};
export const updateWorkshopPhotos = (
  payload: WorkshopPhotos,
  workshopSlug: string
) => {
  return fetchClient.put(
    `${WORKSHOPS.WORKSHOP_PHOTOS}/${workshopSlug}`,
    payload
  );
};

export const confirmPreviousCertification = (attendeesId: string) => {
  return fetchClient.put(
    `${WORKSHOPS.ATTENDEES}/previous-certification-update/${attendeesId}`
  );
};
export const requestPreviousCertification = (
  attendeesId: string,
  workshopLevel: number
) => {
  return fetchClient.put(
    `${WORKSHOPS.ATTENDEES}/request-previous-certification/${attendeesId}/${workshopLevel}`
  );
};

export const getWaitListAttendee = (workshopId: string) => {
  return fetchClient.get(`${WORKSHOPS.WAITLIST}/${workshopId}`);
};

export const sendMailToWaitList = (payload: WaitListEmail) => {
  return fetchClient.post(`${WORKSHOPS.WAITLIST}/send-mail`, payload);
};
export const mailSignWorkshopWaiver = (attendeeId?: string) => {
  return fetchClient.get(
    `${WORKSHOPATTENDEES.SIGN_WORKSHOP_WAIVER}/request-waiver/${attendeeId}`
  );
};
export const aprroveRefundRequest = (attendeeId?: string) => {
  return fetchClient.post(
    `${WORKSHOPS.ATTENDEES}/approve/refund/${attendeeId}`
  );
};

export const rejectRefundRequest = (attendeeId: string, reason: string) => {
  return fetchClient.post(
    `${WORKSHOPS.ATTENDEES}/reject/refund/${attendeeId}`,
    { reason }
  );
};

export const getAttendeesWorkshops = (attendeeId: string) => {
  return fetchClient.get(`${WORKSHOPS.ATTENDEES}/workshops/${attendeeId}`);
};

export const workshopTransferRequest = (
  newWorkshopSlug: string,
  oldWorkshopSlug: string,
  userId?: string
) => {
  return fetchClient.get(
    `${WORKSHOPS.ATTENDEES}/transfer-workshop/${newWorkshopSlug}/${oldWorkshopSlug}/${userId}`
  );
};

export const getInstructorWorkshops = (instructorId: string) => {
  return fetchClient.get(`${INSTRUCTOR.INSTRUCTOR}/workshops/${instructorId}`);
};

export const sendWelcomeEmail = (
  attendeeId: string,
  payload: { attendeeEmail: string }
) => {
  return fetchClient.post(
    `${WORKSHOPS.ATTENDEES}/mail-attendees/${attendeeId}`,
    payload
  );
};

export const addIncome = ({
  payload,
  workshopId,
}: {
  payload: IExternalIncomeFieldValue;
  workshopId: string;
}) => {
  return fetchClient.post(
    `${WORKSHOPS.WORKSHOPS}/income/${workshopId}`,
    payload
  );
};

export const editIncome = ({
  payload,
  workshopId,
  incomeId,
}: {
  payload: IExternalIncomeFieldValue;
  workshopId: string;
  incomeId: string;
}) => {
  return fetchClient.put(
    `${WORKSHOPS.WORKSHOPS}/income/${workshopId}/${incomeId}`,
    payload
  );
};

export const addExpense = ({
  payload,
  workshopId,
}: {
  payload: IExpenseFieldValue;
  workshopId: string;
}) => {
  return fetchClient.post(
    `${WORKSHOPS.WORKSHOPS}/expense/${workshopId}`,
    payload
  );
};

export const editExpense = ({
  payload,
  workshopId,
  expenseId,
}: {
  payload: IExpenseFieldValue;
  workshopId: string;
  expenseId: string;
}) => {
  return fetchClient.put(
    `${WORKSHOPS.WORKSHOPS}/expense/${workshopId}/${expenseId}`,
    payload
  );
};

export const changeWorkshopVisibilityStatus = ({
  payload,
  workshopId,
}: {
  workshopId: string;
  payload: { listedOnBudgetPage: boolean };
}) => {
  return fetchClient.post(
    `${WORKSHOPS.WORKSHOPS}/new-workshops/${workshopId}`,
    payload
  );
};

export const finalizeExpense = ({
  payload,
  workshopId,
}: {
  workshopId: string;
  payload: { isExpensesFinalized: boolean };
}) => {
  return fetchClient.put(
    `${WORKSHOPS.WORKSHOPS}/expense/${workshopId}`,
    payload
  );
};

export const searchAttendees = (
  text: string,
  page?: number,
  limit?: number
) => {
  return fetchClient.get(
    `${WORKSHOPATTENDEES.ATTENDEES}/search?text=${text}&limit=${
      limit ?? 10
    }&page=${page ?? 1}`
  );
};

///seacrh---
export interface WorkshopFilter {
  continents?: string;
  region?: string;
  country?: string;
  state?: string;
  workshopStatus: string;
  technique: string;
  mode: string;
  dateRange: Moment;
}

export const getFilteredWorkshops = (filter: WorkshopFilter) => {
  return fetchClient.post(`${WORKSHOPS.GET_FILTERED_WORKSHOPS}/search`, filter);
};

export const getWorkshopsPnl = (filter: WorkshopFilter) => {
  return fetchClient.post(`${WORKSHOPS.GET_FILTERED_WORKSHOPS}/pnl`, filter);
};

//Workshop coupons start--
export const getWorkshopCoupons = (workshopSlug: string) => {
  return fetchClient.get(`${WORKSHOPS.WORKSHOPS_COUPONS}/${workshopSlug}`);
};

export const addWorkshopCoupon = (
  workshopSlug?: string,
  payload?: IWorkshopCoupon
) => {
  return fetchClient.post(
    `${WORKSHOPS.WORKSHOPS_COUPONS}/${workshopSlug}`,
    payload
  );
};

export const updateWorkshopCouponStatus = (promotionalCodeId: string) => {
  return fetchClient.put(
    `${WORKSHOPS.WORKSHOPS_COUPONS}/codes/${promotionalCodeId}`,
    { active: false }
  );
};

export const deleteWorkshopCoupon = (promotionCodeId: string) => {
  return fetchClient.delete(
    `${WORKSHOPS.WORKSHOPS_COUPONS}/${promotionCodeId}`
  );
};
//Workshop coupons end--

export const transferWorkshopAttendee = ({
  attendeeId,
  payload,
}: {
  attendeeId: string;
  payload: { workshopSlug?: string; transferTo: string; note: string };
}) => {
  return fetchClient.post(
    `${WORKSHOPATTENDEES.ATTENDEES}/transfer-workshop/${attendeeId}`,
    payload
  );
};
//transfer position-
export const transferWorkshopPosition = ({
  attendeeId,
  payload,
}: {
  attendeeId: string;
  payload: { transferToEmail?: string; transferToName: string; note: string };
}) => {
  return fetchClient.post(
    `${WORKSHOPATTENDEES.ATTENDEES}/transfer-position/${attendeeId}`,
    payload
  );
};

export const removeComplementaryPosition = ({
  attendeeId,
}: {
  attendeeId: string;
}) => {
  return fetchClient.delete(
    `${WORKSHOPATTENDEES.ATTENDEES}/remove/${attendeeId}`
  );
};

export const sendCertificationMail = ({
  attendeeId,
}: {
  attendeeId: string;
}) => {
  return fetchClient.get(
    `${WORKSHOPATTENDEES.ATTENDEES}/send-certification-mail/${attendeeId}`
  );
};

// export const generateReceipt = (attendeeId: string) => {
//   return fetchClient.post(`${RECEIPT.CREATE_RECEIPT}/${attendeeId}`);
// };
